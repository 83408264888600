import * as React from "react";
import { Parallax } from "react-parallax";
import { Card, Container, Row } from "react-bootstrap";

import image from "../../images/meeting.jpg";
import stretchinSvg from "../../images/stretching.svg";
import chooseSvg from "../../images/choose.svg";
import Layout from "../../components/layout";
import Section from "../../components/section";
import Typography from "../../components/typography";

const LoanOfficers = () => {
	return (
		<Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
			<Section size="md" color="light" staticHeight>
				<Parallax
					className="d-flex justify-content-center align-items-center h-100"
					bgImage={image}
					strength={750}>
					<Container className="text-center h-100">
						<div>
							<Typography type="head">Business Line of Credit</Typography>
						</div>
					</Container>
				</Parallax>
			</Section>
			<Section size="sm" color="dark" bg="light">
				<Container className=" py-5">
				<div className="hide-on-mobile">
					<Typography className="mb-3" type="title">
						What is a Business Line of Credit?
					</Typography>
					</div>
					<div className="hide-on-desktop text-center">
					<Typography className="mb-3" type="title">
						What is a Business Line of Credit?
					</Typography>
					</div>
					<Typography type="paragraph">
						A business line of credit is a business loan that offers the
						flexibility to borrow up to a certain credit limit, usually between
						a range of $50,000 and $500,000, when capital is needed to cover
						short-term working capital needs. Thus, a pool of money is available
						for access when needed, such as for an emergency, to handle cash
						flow gaps, or to pursue a business opportunity. In addition, no
						interest is incurred and no payments are made until funds are
						actually used. After using a portion of the loan, and that amount is
						paid off, you are eligible to use the full amount of your line of
						credit again. While similar to credit cards, business lines of
						credit have lower interest rates and sometimes lack mandatory
						monthly payments.
					</Typography>
				</Container>
			</Section>
			<Section color="light" bg="dark">
				<Container className="text-center py-5">
					<Typography type="title">Loan Details</Typography>
					<Row className="d-flex justify-content-center mx-auto">
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Max. Loan Amount</Typography>
							<Typography type="subject">$10K to $1M+</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Loan Term</Typography>
							<Typography type="subject">3 - 18 months</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Interest Rates</Typography>
							<Typography type="subject">7% - 25%</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Speed of Funding</Typography>
							<Typography type="subject">24 hours</Typography>
						</Card>
					</Row>
					<Typography className="mt-5" type="title">
						Loan Requirements
					</Typography>
					<Row className="d-flex justify-content-center mx-auto">
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Annual Revenue</Typography>
							<Typography type="subject">$100,000+</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Credit Score</Typography>
							<Typography type="subject">600+</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Time in Business</Typography>
							<Typography type="subject">6+ months</Typography>
						</Card>
					</Row>
				</Container>
			</Section>
			<Section size="md">
				<Container
					fluid
					className="d-flex flex-nowrap h-100 align-items-center h-100 p-5">
					<div className="nom d-inline-block me-5">
						<img
							className="h-100"
							style={{ maxHeight: "275px" }}
							src={stretchinSvg}
							alt="Two People Agreeing on Document Terms"></img>
					</div>
					<div className="d-inline-block flex-full">
						<Row className="w-100 h-100">
							<div className="flex-full">
								<Typography type="title">Pros</Typography>
								<ul>
									<li>Interest only on funds borrowed</li>
									<li>Capital always available if needed</li>
									<li>
										Lines of credit are flexible---good for stabilizing cash
										flows
									</li>
								</ul>
							</div>
							<div className="flex-full mr-5">
								<Typography type="title">Cons</Typography>
								<ul>
									<li>More difficult to qualify for this loan</li>
									<li>
										Hard to increase credit limit --- not ideal for growing
										companies
									</li>
									<li>Lower borrowing limits and additional fees</li>
								</ul>
							</div>
						</Row>
					</div>
				</Container>
			</Section>
			<Section size="sm" bg="dark" color="light">
				<Container className="py-5">
					<div className="hide-on-mobile">
					<Typography type="title">
						How To Apply for a Business Line of Credit
					</Typography>
					<Typography type="paragraph">
						With our simple online application, the process to receive a
						business line of credit is quick and easy.
					</Typography>
					</div>
					<div className="hide-on-desktop">
					<Typography className="text-center" type="title">
						How To Apply for a Business Line of Credit
					</Typography>
					<Typography className="text-center" type="paragraph">
						With our simple online application, the process to receive a
						business line of credit is quick and easy.
					</Typography>
					</div>
					<Row className="mt-4">
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">1</span>
							</Typography>
							<div>
								<Typography type="subject">Prequalify Online</Typography>
								<Typography type="paragraph">
									Follow steps and enter basic information.
								</Typography>
							</div>
						</div>
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">2</span>
							</Typography>
							<div>
								<Typography type="subject">Online Approval</Typography>
								<Typography type="paragraph">
									After online approval, a consultant will reach out to discuss
									loan options.
								</Typography>
							</div>
						</div>
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">3</span>
							</Typography>
							<div>
								<Typography type="subject">Recieve Secure Funding</Typography>
								<Typography type="paragraph">
									Funds will be directly deposited into your bank account and
									available for immediate use.
								</Typography>
							</div>
						</div>
					</Row>

					<div className="hide-on-mobile mt-5">
						<Typography type="title">Needed Documentation</Typography>
						<ul className="d-flex flex-wrap">
							<li className="m-0 me-5 mb-3">
								Basic business and personal details
							</li>
							<li className="m-0 me-5 mb-3">
								Driver's license/government-issued photo ID
							</li>
							<li className="m-0 me-5 mb-3">Recent bank statements</li>
							<li className="m-0 me-5 mb-3">Credit score</li>
						</ul>
					</div>
					<div className="hide-on-desktop mt-5">
						<Typography className="text-center" type="title">Needed Documentation</Typography>
						<ul className="d-flex flex-wrap">
							<li className="m-0 me-5 mb-3">
								Basic business and personal details
							</li>
							<li className="m-0 me-5 mb-3">
								Driver's license/government-issued photo ID
							</li>
							<li className="m-0 me-5 mb-3">Recent bank statements</li>
							<li className="m-0 me-5 mb-3">Credit score</li>
						</ul>
					</div>
				</Container>
			</Section>
			<Section size="md">
				<Container className="d-flex flex-nowrap h-100 align-items-center h-100 py-5">
					<div className="nom d-inline-block flex-full me-5">
						<img
							className="h-100"
							style={{ maxHeight: "400px" }}
							src={chooseSvg}
							alt="Two People Agreeing on Document Terms"></img>
					</div>
					<div className="d-inline-block nom">
						<Typography type="title">
							Best Use of a Business Line of Credit
						</Typography>
						<div className="d-flex flex-wrap mx-auto">
							<ul className="flex-full me-3">
								<li>Ongoing operating expenses</li>
								<li>Cash flow gaps</li>
								<li>Pursuing unexpected business opportunities</li>
							</ul>
							<ul className="flex-full">
								<li>Emergencies</li>
								<li>Covering seasonal shifts in revenue</li>
								<li>Larger purchases</li>
							</ul>
						</div>
					</div>
					<div className="d-inline-block nomm">
						<Typography className="text-center" type="title">
							Best Use of a Business Line of Credit
						</Typography>
						<div className="d-flex flex-wrap mx-auto">
							<ul className="flex-full me-3">
								<li>Ongoing operating expenses</li>
								<li>Cash flow gaps</li>
								<li>Pursuing unexpected business opportunities</li>
								<li>Emergencies</li>
								<li>Covering seasonal shifts in revenue</li>
								<li>Larger purchases</li>
							</ul>
						</div>
					</div>
				</Container>
			</Section>
		</Layout>
	);
};

export default LoanOfficers;
